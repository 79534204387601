import React, { Component } from "react";
import ReservationItems from "./reservations/ReservationItems";
import ReservationsFilter from "./reservations/ReservationsFilter";
import ReservationActionsPanel from "./reservations/ReservationActionsPanel";
import Header from "../components/Header";
import moment from "moment-timezone";
import * as actionCreators from "../modules/actions";
import { connect } from "react-redux";
import * as api from "../utils/api";
import { logError } from '../utils/utils'
import FileSaver from "file-saver";
import ReservationEditor from "./reservations/ReservationEditor";

const errorDisplayLevel = 'detailed'; //'low' or 'medium' or 'detailed' can be later adjusted as a function of Rails.env
const genericErrorMsg = 'An error has occurred. Please try again. If the error persists please contact Sport Compass.';

class Reservations extends Component {

    constructor(props) {
        super(props);
        this.state = {
            locationId: "",
            selReservationIds: [],
            allSelected: false,
            editedReservation: null,
            // acceptedReservation: false,
        };
        this.selectItemHandler = this.selectItemHandler.bind(this);
        this.selectAllHandler = this.selectAllHandler.bind(this);
    }

    clearSelection(){
        this.setState({
            selReservationIds: [],
            allSelected: false
        });
    }

    selectItemHandler(itemId, event) {
        const ids = this.state.selReservationIds;
        const index = ids.indexOf(itemId);
        if (index >= 0) {
            if (!event.target.checked) ids.splice(index, 1);
        } else if (event.target.checked) ids.push(itemId);
        this.setState({
            selReservationIds: ids,
            allSelected: this.props.reservations.length === ids.length
        });
    };

    selectAllHandler(event) {
        const b = event.target.checked;
        this.setState({selReservationIds: b ? this.props.reservations.map(i => i.id) : [], allSelected: b});
    };

    componentDidMount = () => {};
    componentWillUnmount = () => {};

    filterReservations = _filters => {
        this.props.saveReservationsFilters(_filters);
        this.reloadReservations('filter reservations', _filters);
    };

    deleteReservation = reservationId => {
        //DEBUG: console.log('Deleting reservation');
        let props = this.props;
        api.endpoints.deleteReservation(
            reservationId,
            () => {
                this.reloadReservations('delete reservation');
                this.props.refreshMeetups(true);
                this.setState({ editedReservation: null })
            },
            error => {
                let message = 'Error when deleting reservation.';
                const errorMessage = logError(error, message);
                if (errorDisplayLevel === 'detailed') {
                    if (errorMessage)
                        message += `; (${errorMessage})`
                } else
                    message = genericErrorMsg;
                props.hideNotice();
                props.showNotice(message, false, {loading: false});
            });
    };

    acceptReservation = reservationId => {
        let props = this.props;
        api.endpoints.updateReservationValidation(
            reservationId,
            () => {
                this.reloadReservations('validate reservation');
            },
            error => {
                let message = 'Error when accepting reservation'
                const errorMessage = logError(error,message);
                if (errorDisplayLevel === 'detailed') {
                    if(errorMessage)
                    message += `; (${errorMessage})`
                } else 
                    message = genericErrorMsg;
                props.hideNotice();
                props.showNotice(message, false, {loading: false})
            }
        )
        
    };

    rejectReservation = reservationId => {
        let props = this.props;
        api.endpoints.updateReservationRejection(
            reservationId,
            () => {
                this.reloadReservations('reject reservation');
            },
            error => {
                let message = 'Error when rejecting reservation'
                const errorMessage = logError(error,message);
                if (errorDisplayLevel === 'detailed') {
                    if(errorMessage)
                    message += `; (${errorMessage})`
                } else 
                    message = genericErrorMsg;
                props.hideNotice();
                props.showNotice(message, false, {loading: false})
            }
        )
    }

    // seenReservation = reservationId => {
    //     let props = this.props;
    //     api.endpoints.getReservationSeenStatus(
    //         reservationId,
    //         () => {
    //             this.reloadReservations('seen reservation');
    //         }, error => {
    //             let message = 'Error when markig reservation as seen'
    //             const errorMessage = logError(error, message);
    //             if (errorDisplayLevel === 'detailed') {
    //                 if (errorMessage)
    //                     message += `; (${errorMessage})`
    //             } else
    //             message = genericErrorMsg;
    //             props.hideNotice();
    //             props.showNotice(message, false, {loading: false})
    //         }
    //     )
    //     if (this.seenReservation=== 'false') {
            
    //     }
    // };

    deleteReservations = () => {
        //DEBUG: console.log('Deleting reservations');
        const selReservationIds = this.state.selReservationIds;
        let props = this.props;
        api.endpoints.deleteReservations(
            selReservationIds,
            () => {
                this.reloadReservations('delete reservations');
                this.props.refreshMeetups(true);
            },
            error => {
                let message = 'Error when deleting reservations.';
                const errorMessage = logError(error, message);
                if (errorDisplayLevel === 'detailed') {
                    if (errorMessage)
                        message += `; (${errorMessage})`
                } else
                    message = genericErrorMsg;
                props.hideNotice();
                props.showNotice(message, false, {loading: false});
            });
    };

    reloadReservations = (reason, _filters=null) => {
        // DEBUG: console.log(`Reloading reservations. Reason: ${reason}`);
        if (_filters === null)
            _filters = this.props._reservationsFilters;
        let props = this.props;
        this.setState(
            {selReservationIds: [], allSelected: false},
            () =>
                this.props.getReservations({
                    filters: _filters,
                    reason: reason,
                    err: function (error) {
                        let message = `Error in reloading reservations. Reloading reason: ${reason}`
                        const errorMessage = logError(error, message);
                        if (errorDisplayLevel === 'detailed') {
                            if (errorMessage)
                                message += `; (${errorMessage})`
                        } else
                            message = genericErrorMsg;
                        props.hideNotice();
                        props.showNotice(message, false, {loading: false});
                    }
                })
        );
    };

    editReservation = reservationId => {
        if (reservationId) {
            const reservation = this.props.reservations
                .filter(elem => elem.id === reservationId)
                .pop();
                // console.log("reservation", reservation)
            this.setState({ editedReservation: reservation });
        } else {
            this.setState({ editedReservation: null });
        }
    };

    editNewReservation = () => {
        this.setState({ editedReservation: {} });
    };

    renderNoMatchingCriteria = () => (
        <div className="d-flex flex-column w-50 m-auto pt-5 justify-content-center align-items-center">
            <p className="fs-h2 text--gray7 text-center">
                No reservations matching your filters
            </p>
            <p className="fs-body1 text--gray7 text-center">
                There are no reservations that match your search criteria. Please change
                your filters in order to display results.
            </p>
        </div>
    );

    renderNoReservationExists = () => (
        <div className="d-flex flex-column w-50 h-100 pt-5 m-auto justify-content-center align-items-center">
            <p className="fs-h2 text--gray7  text-center">
                Your list looks empty
            </p>
        </div>
    );

    announceNeedToPay = () => {
        this.props.showNotice(process.env.NEED_PAY_MESSAGE, false, { loading: false });
    }

    exportAsExcel = () => {
        //DEBUG: console.log('Exporting reservations as Excel file');
        let props = this.props;
        const selReservationIds = this.state.selReservationIds;
        api.endpoints.printReservations(
            selReservationIds,
                res => {
                    let binaryData = [];
                    binaryData.push(res.data);
                    let file = new File(
                        binaryData,
                        "reservations-" + moment().tz(props.timezone).format("LLL") + ".xlsx",
                        {type: "text/excel;charset=utf-8"}
                    );
                    FileSaver.saveAs(file);
                },
            error => {
                let message = 'Error when exporting reservations as Excel file.'
                const errorMessage = logError(error, message);
                if (errorDisplayLevel === 'detailed') {
                    if (errorMessage)
                        message += `; (${errorMessage})`
                } else
                    message = genericErrorMsg;
                props.hideNotice();
                props.showNotice(message, false, {loading: false});
            });
        this.clearSelection();
    };

    cancelEditing = () => {
        this.setState({ editedReservation: null });
    };

    reservationSaved = () => {
        this.reloadReservations('reservation saved');
        this.setState({ editedReservation: null });
        this.props.refreshMeetups(true);
    };

    renderReservations = () => (
        <div className="col bg-light overflow-auto">
            <Header
                container="reservations"
                additionalBtnClicked={this.editNewReservation}
                additionalBtnLabel="New reservation"
            />
            <div className="d-flex flex-column panel-container">
                {/* Panel with search filter and create reservation button  */}
                <ReservationsFilter
                    filterReservations={this.filterReservations}
                    timezone={this.props.timezone.toString()}
                    aaa={'SSS'}
                    time_format={this.props.time_format}
                />
                {this.props.reservations.length > 0 && <ReservationItems
                    data={this.props.reservations}
                    selectItemHandler={this.selectItemHandler}
                    allSelected={this.state.allSelected}
                    selectAllHandler={this.selectAllHandler}
                    selReservationIds={this.state.selReservationIds}
                    acceptReservation={this.acceptReservation}
                    // seenReservation={this.seenReservation}
                    rejectReservation={this.rejectReservation}
                    editReservation={this.editReservation}
                    timezone={this.props.timezone}
                    time_format={this.props.time_format}
                />}
                {this.props.reservations.length === 0 && (this.props.reservationsExist ? this.renderNoMatchingCriteria() : this.renderNoReservationExists())}
                {this.state.selReservationIds.length > 0 && (
                    // Display selected events and further action if any event is selected
                    <ReservationActionsPanel
                        selReservationIds={this.state.selReservationIds}
                        onDelete={this.deleteReservations}
                        onPrint={this.props.needPay ? this.announceNeedToPay : this.exportAsExcel}
                    />
                )}
            </div>
            {this.state.editedReservation ? (
                <ReservationEditor
                    cancelEditing={this.cancelEditing}
                    deleteReservation={this.deleteReservation}
                    handleSuccess={this.reservationSaved}
                    reservation={this.state.editedReservation}
                    locationId={this.props.locationId}
                    timezone={this.props.timezone}
                    time_format={this.props.time_format}
                />
            ) : null}
        </div>
    );

    render() {
        return (
            <div className="container-fluid">
                <div className="row flex-nowrap h-100">
                    { (this.props.client_loading===false) && this.renderReservations() }
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        reservations: state.reservations,
        reservationsExist: state.reservations_exist,
        _reservationsFilters: state.reservationsFilters,
        needPay: state.currentClientNeedPay,
        client_loading: state.client_loading,
        timezone: state.timezone,
        time_format: state.time_format
    };
};

const mapDispatchToProps = dispatch => {
    return {
        saveReservationsFilters: params => dispatch(actionCreators.saveReservationsFilters(params)),
        getReservations: params => dispatch(actionCreators.getReservations(params)),
        showNotice: (message, success, options) => dispatch(actionCreators.showNotice(message, success, options)),
        hideNotice: params => dispatch(actionCreators.hideNotice(params)),
        refreshMeetups: params => dispatch(actionCreators.refreshMeetups(params))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Reservations);
