import React, { useState, useEffect } from "react";
import CustomInputDate from "./CustomInputDate";
import DatePicker from "react-datepicker";
import Select from "react-select";
import WeekDaysSelector from "./WeekdaysSelector";
import {dtLocalToDtz, dtzWithLocalZone} from "../utils/utils";
import moment from "moment-timezone";
import {ToggleButton, ToggleButtonGroup} from "react-bootstrap";

const RecurrenceSelector = props => {

    const frequencyOptions = [
        { value: 'daily', label: 'Daily' },
        { value: 'weekly', label: 'Weekly' },
        { value: 'monthly', label: 'Monthly' }
    ]

    const [interval, setInterval] = useState(1)
    const [endingOcurrences, setEndingOcurrences] = useState(1)
    const [dateEndOption, setDateEndOption] = useState(true)
    const [recurrenceEndTime, setRecurrenceEndTime] = useState(moment().tz(props.timezone).startOf("hour"))
    const [frequency, setFrequency] = useState(frequencyOptions[0].value)
    const renderWeekdaysSelector = () => (
        <div className="col-3 d-flex flex-column">
            <h4 className="fs-h4 text--gray8 mt-4">Repeat on</h4>
            <WeekDaysSelector
                name="weekday_selector"
                weekDaysRepetition={props.recurrence.weekDaysRepetition}
                onDayClicked={(e) => props.onChange("days", e)}
            />
        </div>
    )

    const renderEndingDate = () => (
        <div className="col-3 d-flex flex-column">
            <h4 className="fs-h4 text--gray8 mt-4">End date</h4>
            <DatePicker
                selected={dtzWithLocalZone(recurrenceEndTime)}
                dateFormat="dd/MM/yyyy"
                strictParsing={true}
                name="recurring_end_date"
                onChange={(date) => {
                    const date_tz = dtLocalToDtz(date, props.timezone);
                    props.onChange("recurrence_end_time", date_tz)
                    setRecurrenceEndTime(date_tz)
                }}
                locale="en-GB"
                useWeekdaysShort={true}
                monthsShown={1}
                disabled={!dateEndOption}
                customInput={<CustomInputDate />}
            />
        </div>
    )
    const renderEndAfterOcurrences = () => (
        <div className="col-3 d-flex flex-column">
            <h4 className="fs-h4 text--gray8 mt-4">End after</h4>
            <input
                type="number"
                placeholder="Occurrences number"
                value={endingOcurrences}
                onChange={e => {
                    props.onChange("ending_occurrences_number", e.target.value)
                    setEndingOcurrences(e.target.value)
                }}
                disabled={dateEndOption}
            /> ocurrences
        </div>
    )

    return (
        <div className="row form-group">
            <div className="col-3 d-flex flex-column">
                <h4 className="fs-h4 text--gray8 mt-4">Frequency</h4>
                <Select
                    options={frequencyOptions}
                    placeholder="Frequency"
                    onChange={(e) => {
                        props.onChange("occurrences", e.value)
                        setFrequency(e.value)
                    }}
                    defaultValue={frequencyOptions[0]}
                />
            </div>
            { frequency === 'weekly' ? renderWeekdaysSelector() : null }

            <div className="col-3 d-flex flex-column">
                <h4 className="fs-h4 text--gray8 mt-4">Repeat every</h4>
                <input
                    type="number"
                    placeholder="Repeat every"
                    value={interval}
                    onChange={e => {
                        props.onChange("interval", e.target.value)
                        setInterval(e.target.value)
                    }}
                /> { frequency === 'daily' ? 'day(s)' : frequency === 'weekly' ? 'week(s)' : 'month(s) '}
            </div>

            <div className="col-3 d-flex flex-column">
                <h4 className="fs-h4 text--gray8 mt-4">Ending option</h4>
                <ToggleButtonGroup type="radio" name="ending_option" defaultValue={"on_date"} onChange={() => setDateEndOption(!dateEndOption)}>
                    <ToggleButton
                        key="on-date-btn"
                        value="on_date"
                        className="togglebutton-delete_modal"
                    >
                        On date
                    </ToggleButton>
                    <ToggleButton
                        key="after-occurrences-btn"
                        value="after_occurrences"
                        className="togglebutton-delete_modal"
                    >
                        After occurrences
                    </ToggleButton>
                </ToggleButtonGroup>
            </div>
            { dateEndOption ? renderEndingDate() : renderEndAfterOcurrences() }
        </div>
    )
}

export default RecurrenceSelector
