import React from "react";
import { Field } from "formik";

const EventTypeFields = ({ eventForm = {}, onChange }) => {
  const eventType = eventForm.event_type || "";

  const sportOptions = [
    { value: "", label: "Select sport" },
    { value: "football", label: "football" },
    { value: "rugby", label: "rugby" },
    { value: "american football", label: "american football" },
    { value: "handball", label: "handball" },
    { value: "combat sports", label: "combat sports" },
    { value: "hockey", label: "hockey" },
    { value: "racing", label: "racing" },
    { value: "tennis", label: "tennis" },
    { value: "basketball", label: "basketball" },
    { value: "gaelic football", label: "gaelic football" },
    { value: "australian rules football", label: "australian rules football" },
    { value: "baseball", label: "baseball" },
    { value: "horse racing", label: "horse racing" },
    { value: "volleyball", label: "volleyball" },
    { value: "custom", label: "custom" },
    { value: "cricket", label: "cricket" },
    { value: "badminton", label: "badminton" },
    { value: "cycling", label: "cycling" },
    { value: "other", label: "other" },
  ];

  const nonSportOptions = [
    { value: "", label: "Select non type" },
    { value: "Open mic", label: "Open mic" },
    { value: "Karaoke", label: "Karaoke" },
    { value: "Dj night", label: "Dj night" },
    { value: "Pub quiz", label: "Pub quiz" },
    { value: "Lady's night", label: "Lady's night" },
    { value: "Salsa night", label: "Salsa night" },
    { value: "Latino night", label: "Latino night" },
    { value: "Guys night", label: "Guys night" },
    { value: "Happy hour", label: "Happy hour" },
  ];

  let eventSubOptions = [];
  if (eventType === "Sport") {
    eventSubOptions = sportOptions;
  } else if (eventType === "non Sport") {
    eventSubOptions = nonSportOptions;
  }

  const handleEventTypeChange = (e) => {
    const newType = e.target.value;
    onChange("event_type", newType);
    // Reset event_sub_type when event_type changes
    onChange("event_sub_type", "");
  };

  const handleEventSubTypeChange = (e) => {
    onChange("event_sub_type", e.target.value);
  };

  return (
      <div className="row mb-3">
        {/* Column for "Event type" */}
        <div className="col-6">
          <label htmlFor="event_type" className="mb-1">
            Event type
          </label>
          <Field
              as="select"
              name="event_type"
              id="event_type"
              className="form-control"
              value={eventType}
              onChange={handleEventTypeChange}
          >
            <option value="">Select event type</option>
            <option value="Sport">Sport</option>
            <option value="non Sport">non Sport</option>
          </Field>
        </div>

        {/* Column for "Event sub type" */}
        <div className="col-6">
          <label htmlFor="event_sub_type" className="mb-1">
            Event sub type
          </label>
          <Field
              as="select"
              name="event_sub_type"
              id="event_sub_type"
              className="form-control"
              value={eventForm.event_sub_type || ""}
              onChange={handleEventSubTypeChange}
              disabled={!eventType}
          >
            {eventSubOptions.length === 0 ? (
                <option value="">Select event type first</option>
            ) : (
                eventSubOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                ))
            )}
          </Field>
        </div>
      </div>
  );
};

export default EventTypeFields;
