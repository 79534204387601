import React, { Component } from "react";
import { Button } from "react-bootstrap-buttons";
import DatePicker, { registerLocale } from "react-datepicker";
import { Formik, Form, Field } from "formik";
import enGB from "date-fns/locale/en-GB";
import "react-datepicker/dist/react-datepicker.css";
import CustomInputDate from "./CustomInputDate";
import CustomInputTime from "./CustomInputTime";
import moment from "moment-timezone";
import { dtzWithLocalZone, dtLocalToDtz } from "../utils/utils";
import * as api from "../utils/api";
import { validateTextField, renderFieldError } from "../utils/validation";
import * as actionCreators from "../modules/actions";
import { connect } from "react-redux";
import RecurrenceSelector from "./RecurrenceSelector";
import CustomSwitch from "./CustomSwitch";
import CustomAttributeToggle from "./CustomAttributeToggle";
import EventTypeFields from "./EventTypeFields";

/**Register the locale to display calendar in UK format, week starting with Monday */
registerLocale("en-GB", enGB);
/**Store the folder path for webpack created images folder */
const images = require.context("../assests/images", true);
const today = new Date();
const defaultRecurrence = {
  startDate: today,
  endDate: today,
  frequency: "weekly",
  numberOfRepetitions: 1,
  weekDaysRepetition: [],
  endingOccurrencesNumber: 0,
  isAllDay: false,
  startTime: today,
  endTime: today,
  layout: 'horizontal',
};

function emptyEvent(timezone) {
  return {
    name: "",
    start_time: moment().tz(timezone).startOf("hour"),
    end_time: moment().tz(timezone).add(1, "hour").startOf("hour"),
    occurrences: "daily",
    description: "",
    recurring: false,
    recurrence_start_time: moment().tz(timezone).startOf("hour"),
    recurrence_end_time: moment().tz(timezone).add(1, "hour").startOf("hour"),
    days: [],
    interval: 1,
    ending_occurrences_number: null,
  };
}

/**Component displayed as a modal popup with input for title, description and event date/time for custom event creation
 * @param cancelModal : Cancel Modal when clicked outside
 * @param evtForm : Form element with event title and description input fields
 * @param moment = prop for date picker element for default date
 * @param handleDateSelect = prop for date picker for handling date selection
 * @param onChange = Handler for event title and desc onChange
 * @param createEvent = Handler for create event button click
 *
 * Imported compoenent DatePicker for the date selection feature
 * CustomInputDate sent as prop to DatePicker to render the customizated
 * input text field with calender icon at right
 */
class Eventpopup extends Component {
  constructor(props) {
    super(props);
    let isRecurring = props.meetupObj?.event?.recurring;
    let formDataObj = emptyEvent(props.timezone);
    if (props.meetupObj) {
      const { start_time, end_time, description } = props.meetupObj.event;
      const mo_start_time = moment(start_time).tz(props.timezone);
      formDataObj = {
        ...props.meetupObj.event,
        description: description ? description : "",
        start_time: mo_start_time,
        end_time: end_time ? moment(end_time).tz(props.timezone) : mo_start_time,
      };
      this.setState({ eventForm: formDataObj });
    }
    this.state = {
      isRecurring: isRecurring,
      eventForm: formDataObj,
    };
  }
  onSuccess = () => {
    this.props.handleSuccess();
    this.props.showNotice(
      "The custom event was successfully " + (this.props.meetupObj ? "saved" : "created"),
      true
    );
  };


  onError = (err) => {
    this.props.showNotice(err.response.data.message, false);
  };

  /**Creates a new custom event based on user input
   */
  createCustomEvent = () => {
    const eventObj = { ...this.state.eventForm };
    api.endpoints.createCustomEvent(
        this.props.locationId,
        eventObj,
        this.onSuccess,
        this.onError
    );
  };

  editCustomEvent = () => {
    const eventObj = { ...this.state.eventForm };
    api.endpoints.editCustomEvent(
      this.props.locationId,
      this.props.meetupObj.event.id,
      eventObj,
      this.props.editMode,
      this.onSuccess,
      this.onError
    );
  };

  /**Cancels the create custom event modal
   */
  cancelModal = () => {
    this.props.cancelModal();
  };

  /**OnChange handler for fields in custom event form
   *@param fieldvalue= value,
   *@param field= identifier to identify the input element on the form.
   */
  changeHandler = (field, fieldValue) => {
    const eventObj = { ...this.state.eventForm };
    eventObj[field] = fieldValue;
    if (field === "start_time") {
      const d = fieldValue.toObject();
      eventObj["end_time"].set({
        year: d.years,
        month: d.months + 1,
        day: d.date,
      });
    }
    this.setState({
      eventForm: eventObj,
    });
  };

  handleChangeValue = (field, fieldValue, name) => {
    this.changeHandler(field, fieldValue);
    return name;
  };
  render() {
    return (
        <div>
          <div className="modal-backdrop bg-secondary opacity6" />
          <div className="modal d-inline-block">
            <div className="modal-dialog-centered modal__custom-event modal__custom-event--dialog-width">
              <div className="modal-content pl-5 pr-5 pt-3 pb-3  m-auto bg--gray1">
                <Formik
                    enableReinitialize={true}
                    initialValues={{
                      event_date:
                          this.state.eventForm.start_time.toDate() === undefined || ""
                              ? ""
                              : this.state.eventForm.start_time.toDate(),
                      event_title:
                          this.state.eventForm.name === undefined || ""
                              ? ""
                              : this.state.eventForm.name,
                      recurring: this.state.eventForm.recurring || "",
                      event_type: this.state.eventForm.event_type || "",
                      event_sub_type: this.state.eventForm.event_sub_type || "",
                      sport_type: this.state.eventForm.sport_type || "",
                      team1: this.state.eventForm.team1 || "",
                      team2: this.state.eventForm.team2 || "",
                    }}
                    onSubmit={(values) => {
                      this.props.meetupObj
                          ? this.editCustomEvent()
                          : this.createCustomEvent();
                    }}
                >
                  {({ errors, touched, isSubmitting, setFieldValue }) => (
                      <Form>
                        <div className="modal-header pl-0 py-3">
                          <div className="d-flex">
                            <div className="d-flex pt-2 flex-row col-1 border border-left-0 border-top-0 border-bottom-0">
                              <CustomAttributeToggle
                                  text="Recurring"
                                  enabled={this.state.isRecurring}
                                  switchClass="feature-switch"
                                  onToggle={(checked) => {
                                    this.setState({ isRecurring: checked });
                                    this.changeHandler("recurring", checked);
                                  }}
                              />
                            </div>
                            <div className="d-flex flex-column col-6 border border-left-0 border-top-0 border-bottom-0">
                              <label className="fs-body2">Event Date</label>
                              <DatePicker
                                  selected={dtzWithLocalZone(
                                      this.state.eventForm.start_time
                                  )}
                                  dateFormat="dd/MM/yyyy"
                                  strictParsing={true}
                                  name="event_date"
                                  onChange={(date) => {
                                    if (date !== null) {
                                      const date_tz = dtLocalToDtz(
                                          date,
                                          this.props.timezone
                                      );
                                      return setFieldValue(
                                          this.handleChangeValue(
                                              "start_time",
                                              date_tz,
                                              "event_date"
                                          ),
                                          date_tz.toDate().toDateString()
                                      );
                                    }
                                  }}
                                  locale="en-GB"
                                  useWeekdaysShort={true}
                                  monthsShown={1}
                                  customInput={<CustomInputDate />}
                              />
                              <div className="text-danger">{errors.event_date}</div>
                            </div>
                            <div className="d-flex flex-column col-4 border border-left-0 border-top-0 border-bottom-0">
                              <label className="fs-body2">Event Time</label>
                              <div className=" d-flex col-11 p-0  ">
                                <DatePicker
                                    name="start_time"
                                    selected={dtzWithLocalZone(
                                        this.state.eventForm.start_time
                                    )}
                                    dateFormat={
                                      this.props.time_format === "24H"
                                          ? "HH:mm"
                                          : "hh:mm aa"
                                    }
                                    onChange={(date) => {
                                      if (date !== null) {
                                        const date_tz = dtLocalToDtz(
                                            date,
                                            sessionStorage.getItem("timezone")
                                        );
                                        this.changeHandler("start_time", date_tz);
                                      }
                                    }}
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeCaption=""
                                    timeFormat={
                                      this.props.time_format === "24H"
                                          ? "HH:mm"
                                          : "hh:mm aa"
                                    }
                                    customInput={<CustomInputTime />}
                                />

                                <div className="p-2" />
                                <DatePicker
                                    name="end_time"
                                    selected={dtzWithLocalZone(
                                        this.state.eventForm.end_time
                                    )}
                                    dateFormat={
                                      this.props.time_format === "24H"
                                          ? "HH:mm"
                                          : "hh:mm aa"
                                    }
                                    onChange={(date) => {
                                      if (date !== null) {
                                        this.changeHandler(
                                            "end_time",
                                            dtLocalToDtz(date, this.props.timezone)
                                        );
                                      }
                                    }}
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeCaption=""
                                    timeFormat={
                                      this.props.time_format === "24H"
                                          ? "HH:mm"
                                          : "hh:mm aa"
                                    }
                                    customInput={<CustomInputTime />}
                                />
                              </div>
                            </div>
                            <div className="pr-3 pl-0 pt-3 col-1 pl-4 ">
                              <img
                                  src={images("./img/custom-event.svg")}
                                  alt="event"
                                  className="icon-img-size"
                              />
                            </div>
                          </div>
                          <div className="d-flex">
                            <div></div>
                          </div>
                        </div>
                        <div className="modal-body px-0 ml-0 bg--gray1">
                          {this.state.isRecurring && (
                              <RecurrenceSelector
                                  recurrence={defaultRecurrence}
                                  eventForm={this.state.eventForm}
                                  timezone={this.props.timezone}
                                  onChange={(field, value) => {
                                    this.changeHandler(field, value);
                                  }}
                              />
                          )}
                          <EventTypeFields
                              eventForm={this.state.eventForm}
                              onChange={(field, value) =>
                                  this.setState((prevState) => ({
                                    eventForm: {
                                      ...prevState.eventForm,
                                      [field]: value,
                                    },
                                  }))
                              }
                          />
                          <div className="row form-group mb-3">
                            <div className="col-12 flex-column">
                              <label className="fs-body2">Event Title</label>
                              <Field
                                  type="text"
                                  name="event_title"
                                  className="form-control"
                                  placeholder="Your event name"
                                  value={this.state.eventForm.name}
                                  validate={validateTextField}
                                  onChange={(event) => {
                                    setFieldValue(
                                        this.handleChangeValue(
                                            "name",
                                            event.target.value,
                                            "event_title"
                                        ),
                                        event.target.value
                                    );
                                  }}
                              />
                            </div>
                          </div>
                          <div className="row form-group">
                            <div className="col-12 flex-column">
                              <label className="fs-body2">Event Descriptionn</label>
                              <textarea
                                  rows="6"
                                  col="250"
                                  type="text"
                                  className="form-control align-top"
                                  placeholder="Your event description"
                                  value={this.state.eventForm.description}
                                  onChange={(event) => {
                                    this.changeHandler(
                                        "description",
                                        event.target.value
                                    );
                                  }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="modal-footer p-0 border-0">
                          <Button
                              type="button"
                              className="button-warning--modal p-1 mr-2"
                              onClick={this.cancelModal}
                          >
                            Cancel
                          </Button>
                          <Button
                              type="submit"
                              className="button-success--modal p-1"
                              disabled={isSubmitting}
                          >
                            {(this.props.meetupObj ? "Edit" : "Create") + " event"}
                          </Button>
                        </div>
                      </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    showNotice: (message, success) =>
        dispatch(actionCreators.showNotice(message, success)),
  };
};

export default connect(null, mapDispatchToProps)(Eventpopup);
